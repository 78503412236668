// src/components/DenseSnowfall.js
import React from 'react';
import Snowfall from 'react-snowfall';

const DenseSnowfall = () => {
    return (
        <Snowfall
            color="white"
            snowflakeCount={200} // Neige dense
            style={{
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                zIndex: 10, // Z-index élevé pour apparaître par-dessus d’autres éléments
                pointerEvents: 'none', // Empêche la neige d’interférer avec les clics
            }}
        />
    );
};

export default DenseSnowfall;
