// src/firebaseConfig.js
import { initializeApp } from "firebase/app";
import { getFirestore, collection } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyCnxBgRRTe-h8a3yN_HMurDKKII3CbbssM",
    authDomain: "tamara-ae6f3.firebaseapp.com",
    projectId: "tamara-ae6f3",
    storageBucket: "tamara-ae6f3.appspot.com",
    messagingSenderId: "483759836363",
    appId: "1:483759836363:web:5a51fff46fde7e30aedf4e"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export { db, collection };
