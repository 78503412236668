// src/App.js
import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ItemList from './components/ItemList';
import AdminPage from './components/AdminPage';
import ThankYouModal from './components/ThankYouModal';
import DenseSnowfall from './components/DenseSnowfall'; // Neige en arrière-plan
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';

const App = () => {
    const [isModalOpen, setIsModalOpen] = useState(true);

    const closeModal = () => {
        setIsModalOpen(false);
    };

    return (
        <Router>
            <div className="App">
                <header className="App-header">
                    <h1>Liste de Noël</h1>
                </header>
                <main>
                    <Routes>
                        <Route path="/" element={<ItemList />} />
                        <Route path="/admin" element={<AdminPage />} />
                    </Routes>
                </main>
                <ToastContainer />
                {/* Neige en arrière-plan, toujours visible */}
                <DenseSnowfall />
                <ThankYouModal isOpen={isModalOpen} onRequestClose={closeModal} />
            </div>
        </Router>
    );
};

export default App;
