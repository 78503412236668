// src/components/ThankYouModal.js
import React from 'react';
import Modal from 'react-modal';
import './ThankYouModal.css';
import bgThankYou from './IMG_9972-preview-min.png';
import Snowfall from "react-snowfall";

Modal.setAppElement('#root');

const ThankYouModal = ({ isOpen, onRequestClose }) => {
    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            className="Modal"
            overlayClassName="Overlay"
        >
            <div className="modal-content">
                <Snowfall snowflakeCount={200} color="white" />

                <img src={bgThankYou} alt="Thank you" className="modal-image" />
                <h2>Mon tout premier Noël avec vous ! 🎄❤️</h2>
                <p>
                    Ho ho ho ! 🎅 C’est bientôt mon tout premier Noël ! Merci à tous les papas et mamans Noël qui me gâtent et m’entourent de magie ✨. J’ai hâte de vous voir tous, de vous faire plein de sourires et de passer ce beau moment avec vous. À très vite pour un Noël plein de câlins et de rires ! 🎄💖
                </p>
                <p>Arya</p>
                <button onClick={onRequestClose} className="close-button">Fermer</button>
            </div>
        </Modal>
    );
};

export default ThankYouModal;
