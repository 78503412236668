// src/components/ItemList.js
import React, { useState, useEffect } from 'react';
import { db } from '../firebaseConfig';
import { collection, getDocs, updateDoc, doc } from "firebase/firestore";
import { toast } from 'react-toastify';
import { FaGift, FaTimesCircle } from 'react-icons/fa'; // Icônes pour un thème de Noël
import './ItemList.css';

const ItemList = () => {
    const [items, setItems] = useState([]);
    const [name, setName] = useState('');

    useEffect(() => {
        const fetchItems = async () => {
            const itemsCollection = collection(db, 'items');
            const itemsSnapshot = await getDocs(itemsCollection);
            const fetchedItems = itemsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

            // Trier les objets par ordre alphabétique
            fetchedItems.sort((a, b) => a.name.localeCompare(b.name));
            setItems(fetchedItems);
        };

        fetchItems();
    }, []);

    const handleDonate = async (id) => {
        if (name.trim() === '') {
            toast.error('Eh oh, on veut savoir qui offre le cadeau ! Veuillez entrer votre prénom 🎅');
            return;
        }

        const itemDoc = doc(db, 'items', id);
        await updateDoc(itemDoc, { purchasedBy: name });
        setItems(items.map(item => item.id === id ? { ...item, purchasedBy: name } : item));
        toast.success('Merci petit papa Noël ! 🎅');
    };

    // Séparer les objets réservés des objets disponibles
    const availableItems = items.filter(item => !item.purchasedBy);
    const reservedItems = items.filter(item => item.purchasedBy);

    return (
        <div>
            <h2>Liste des cadeaux de Noël</h2>
            <div className="input-container">
                <input
                    type="text"
                    placeholder="Entrez votre prénom"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />
            </div>
            <h3>Cadeaux disponibles</h3>
            <ul className="item-list">
                {availableItems.map(item => (
                    <li key={item.id} className="item available">
                        <div className="item-content">
                            <span>{item.name}</span>
                            {item.url && <a href={item.url} target="_blank" rel="noopener noreferrer">Voir le cadeau</a>}
                        </div>
                        <button onClick={() => handleDonate(item.id)} className="item-button">
                            <FaGift /> Offrir
                        </button>
                    </li>
                ))}
            </ul>
            <h3>Cadeaux réservés par les lutins 🎅</h3>
            <ul className="item-list">
                {reservedItems.map(item => (
                    <li key={item.id} className="item reserved">
                        <div className="item-content">
                            <span>{item.name} - Offert par {item.purchasedBy}</span>
                            {item.url && <a className="a-reserved" href={item.url} target="_blank" rel="noopener noreferrer">Voir le cadeau</a>}
                        </div>
                        <FaTimesCircle className="item-icon" />
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default ItemList;
